document.addEventListener('DOMContentLoaded', (event) => {
    loadTooltips();
    const button = document.querySelector('#button');
    const tooltip = document.querySelector('#tooltip');
})

function loadTooltips() {
    const tooltips = document.querySelectorAll('.tooltip-wrapper');
    tooltips.forEach(tooltipWrapper => {
        const button = tooltipWrapper.querySelector('.tooltip-button');
        const tooltip = tooltipWrapper.querySelector('.tooltip');

        const popperInstance = Popper.createPopper(button, tooltip, {
            placement: "auto",
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
                {
                    name: "flip", //flips popper with allowed placements
                    options: {
                        allowedAutoPlacements: ["right", "left", "top", "bottom"],
                        rootBoundary: "viewport"
                    }
                }
            ],
        });

        function show() {
            // Make the tooltip visible
            tooltip.setAttribute('data-show', '');

            // Enable the event listeners
            popperInstance.setOptions((options) => ({
                ...options,
                modifiers: [
                    ...options.modifiers,
                    { name: 'eventListeners', enabled: true },
                ],
            }));

            // Update its position
            popperInstance.update();
        }

        function hide() {
            // Hide the tooltip
            tooltip.removeAttribute('data-show');

            // Disable the event listeners
            popperInstance.setOptions((options) => ({
                ...options,
                modifiers: [
                    ...options.modifiers,
                    { name: 'eventListeners', enabled: false },
                ],
            }));
        }

        const showEvents = ['click', 'focus'];
        const hideEvents = ['blur'];

        showEvents.forEach((event) => {
            button.addEventListener(event, show);
        });

        hideEvents.forEach((event) => {
            button.addEventListener(event, hide);
        });
    });
}
