function initMap(mapId, endpoint) {
    L.Map.addInitHook(function () {
        // Store a reference of the Leaflet map object on the map container,
        // so that it could be retrieved from DOM selection.
        // https://leafletjs.com/reference.html#map-getcontainer
        this.getContainer()._leaflet_map = this;
    });

    var map = L.map(mapId, {
        minZoom: 3,
        maxZoom: 18,
        tap: false
    }).fitWorld();

    var gl = L.mapboxGL({
        attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
        style: 'https://api.maptiler.com/maps/76839e4d-9be2-47cb-b03f-6e3edff2fc45/style.json?key=fga3W1z8bUf9A5zJthaz'
    }).addTo(map);

    //L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //    attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
    //}).addTo(map);

    setMarkers(endpoint, map);
}

function setMarkers(endpoint, map) {

    let poiOptions = {
        'autoPanPadding': [60, 15]
    }

    var markerIcon = L.icon({
        iconUrl: '/dist/img/marker-icon.svg',
        //shadowUrl: '/dist/img/marker-shadow.png',

        iconSize: [25, 37],
        //shadowSize: [41, 41],
        iconAnchor: [13, 41],
        //shadowAnchor: [13, 41],
        popupAnchor: [0, -45]
    });

    var ajaxRequest = new XMLHttpRequest();
    ajaxRequest.onreadystatechange = function () {

        if (ajaxRequest.readyState == 4) {
            //the request is completed, now check its status
            if (ajaxRequest.status == 200) {
                var mapMarkers = L.featureGroup().addTo(map);
                var markers = JSON.parse(ajaxRequest.responseText);

                var length = markers.length;
                for (var i = 0; i < length; i++) {
                    var poi = markers[i];
                    var marker = L.marker([poi.marker.latitude, poi.marker.longitude], { icon: markerIcon });
                    marker.bindPopup(poi.text, poiOptions);

                    marker.addTo(mapMarkers)
                }

                if (length > 0) {
                    map.fitBounds(mapMarkers.getBounds(), { padding: [0, 0] });
                }
            }
        }


    }
    ajaxRequest.open('GET', endpoint);
    ajaxRequest.send();
}
