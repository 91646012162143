document.addEventListener('alpine:init', () => {
    const priceData = {
        prices: {},
        reload() {
            sessionStorage.removeItem(this.productId);
            this.init(this.productId);
        },
        init() {
            const cachedPrices = JSON.parse(sessionStorage.getItem(this.productId));

            if (cachedPrices) {
                // make it accessible to x-data
                this.prices = cachedPrices;
                return;
            }

            // get product data
            this.getPrices()
        },
        getPrices() {
            fetch('/umbraco/api/pricesapi/GetProductPrice?key=' + this.productId + '&area=' + this.area)
                .then(response => response.json())
                .then(response => {
                    this.prices = response;
                    sessionStorage.setItem(this.productId, JSON.stringify(this.prices));
                    sessionStorage.setItem("area", this.area);
                });
        }
    }
    window.priceData = priceData;
});
